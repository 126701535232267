import React from 'react';
import ComparisonChart from './ComparisonChart';
import MetricsSelector from './MetricsSelector';

import './ChartControls.css';

class ChartControls extends React.Component {
  render() {
    return (
      <div className='chart-controls-container'>
        <ComparisonChart />
        <MetricsSelector supportedMetrics={this.props.supportedMetrics} />
      </div>
    );
  }
}

export default ChartControls;