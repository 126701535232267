import React from 'react';
import { connect } from 'react-redux';
import chroma from 'chroma-js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Scatter } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const COLOR_RANGE = ['00429d', '96ffea', 'd1d1b3', 'ffffe0', 'ff005e', '93003a'];

class ComparisonChart extends React.Component {
  render() {
    const { chartData, filters } = this.props;
    const { labels, series } = chartData;

    let chartComponent;

    if (labels && series) {
      const backgroundColor = chroma
        .bezier(COLOR_RANGE)
        .scale(/*'Spectral'*/)
        // .correctLightness()
        .colors(labels.length);

      if (series.length === 1) {
        chartComponent = (<Chart1D backgroundColor={backgroundColor} chartData={chartData} filters={filters} />);
      } else {
        chartComponent = (<Chart2D backgroundColor={backgroundColor} chartData={chartData} filters={filters} />);
      }
    }

    return (
      <div className='comparison-chart-container' style={{ marginBottom: '30px' }}>
        {chartComponent}
      </div>
    );
  }
}

class Chart1D extends React.Component {
  render() {
    const { backgroundColor, chartData, filters } = this.props;
    const labels = chartData.labels
      .map((label, index) =>
        (filters[label])
          ? label.split(':')[0]
          : null
      )
      .filter(_ => _ !== null);

    const datasets = chartData.series.map(series => {
        return ({
          label: series.title,
          backgroundColor,
          data: series.values
            .map((value, index) => {
              const symbol = chartData.labels[index];
              return (filters[symbol])
                ? value
                : null;
            })
            .filter(_ => _ !== null),
        });
      });
    const data = {
      labels,
      datasets,
    };

    const options = {
      animation: false,
      responsive: true,
      scales: {
        y: {
          ticks: {
            callback: TICK_CALLBACK[chartData.series[0].dataType],
          },
          title: {
            display: true,
            padding: 10,
            text: chartData.series[0].title,
          },
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (value) => {
              const fn = TOOLTIP_LABEL_CALLBACK[chartData.series[0].dataType];
              return (typeof fn === 'function')
                ? fn(value.raw)
                : value.raw;
            },
          }
        },
      },
    };

    return (
      <Bar
        options={options}
        data={data}
      />
    );
  }
}

class Chart2D extends React.Component {
  render() {
    const { backgroundColor, chartData, filters } = this.props;
    const { labels, series } = chartData;

    const datasets = labels.map((label, index) => {
      const data = [{
        x: series[0].values[index],
        y: series[1].values[index],
      }];
      return {
        label,
        data,
        backgroundColor: backgroundColor[index],
      }
    });
    const data = {
      datasets: datasets.filter(data => filters[data.label]),
    };

    const options = {
      animation: false,
      responsive: true,
      elements: {
        point: {
          radius: 6,
          hoverRadius: 8,
        },
      },
      scales: {
        x: {
          ticks: {
            callback: TICK_CALLBACK[chartData.series[0].dataType],
          },
          title: {
            display: true,
            padding: 10,
            text: chartData.series[0].title,
          },
        },
        y: {
          ticks: {
            callback: TICK_CALLBACK[chartData.series[1].dataType],
          },
          title: {
            display: true,
            padding: 10,
            text: chartData.series[1].title,
          },
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (value) => {
              const { raw, dataset: { label } } = value;
              const xFn = TOOLTIP_LABEL_CALLBACK[series[0].dataType];
              const xValue = (typeof xFn === 'function')
                ? xFn(raw.x)
                : raw.x;
              const yFn = TOOLTIP_LABEL_CALLBACK[series[1].dataType];
              const yValue = (typeof yFn === 'function')
                ? yFn(raw.y)
                : raw.y;
              return [
                label.split(':')[0],
                `${series[0].title}: ${xValue}`,
                `${series[1].title}: ${yValue}`
              ];
            },
          }
        },
      },
    };

    return (<Scatter options={options} data={data} />);
  }
}

function formatBigMoney(value) {
  const abs = Math.abs(value);
  if (abs > 999999999999) {
    return `$${Math.round(value/1000000000000 * 100) / 100}T`;
  }
  if (abs > 999999999) {
    return `$${Math.round(value/1000000000 * 100) / 100}B`;
  }
  if (abs > 999999) {
    return `$${Math.round(value/1000000 * 100) / 100}M`;
  }
  return `$${value}`;
}

function formatDecimal(value) {
  return Math.round(value * 100) / 100;
}

export const DATA_TYPE = {
  BIG_MONEY: 'big_money',
  MONEY: 'money',
  DECIMAL: 'decimal',
};

const TICK_CALLBACK = {
  [DATA_TYPE.BIG_MONEY]: formatBigMoney,
  [DATA_TYPE.DECIMAL]: formatDecimal,
};

const TOOLTIP_LABEL_CALLBACK = {
  [DATA_TYPE.BIG_MONEY]: value => {
    return `USD ${formatBigMoney(value)}`;
  },
  [DATA_TYPE.DECIMAL]: formatDecimal,
};

const mapState = state => (state.MetricsSelector);
const mapDispatch = dispatch => (dispatch.MetricsSelector);

export default connect(mapState, mapDispatch)(ComparisonChart);