import axios from "axios";

export async function loadCompanies(filterBySectors) {
  try {
    const response = await axios
      .get('/.netlify/functions/companies', {
        params: {
          sectors: filterBySectors.join(','),
        }
      });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function loadStatistics(tickers, metrics) {
  try {
    const response = await axios
      .get('/.netlify/functions/statistics', {
        params: {
          tickers: tickers.join(','),
          metrics: metrics.join(','),
        }
      });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}