import React from 'react';
import SectorCard from './SectorCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCircleCheck, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import './SectorCardCollection.css';

class SectorCardCollection extends React.Component {
  render() {
    const parentNode = this.props.parent;
    const backButton = parentNode
      ? (<FontAwesomeIcon icon={faArrowLeft} onClick={this.props.backHandler} />)
      : null;
    const parentNodeLabel = parentNode ? parentNode.name : 'Sectors';
    const cards = this.props.sectors.map(sector => {
      return (
        <SectorCard
          key={sector.id}
          id={sector.id}
          cardImage={sector.image}
          title={sector.name}
          desc={sector.desc}
          selected={!!this.props.selections.get(sector.id)}
          disabled={this.props.disabled}
          actions={this.generateCardActions(sector)} />
      );
    });
    return (
      <div className={`collection-container ${this.props.disabled ? 'disabled' : 'active'}`}>
        <div className='parent-node-label'>
          {backButton}
          <h1>{parentNodeLabel}</h1>
        </div>
        <div className='card-collection'>
          {cards}
        </div>
      </div>
    );
  }

  generateCardActions(sector) {
    const buttonClassModifier = (index, props) => (index === 0 && props.selected ? 'selected' : '');
    const selectHandler = () => {
      const nodesInTree = sector.getAllNodes();
      const sectorIds = nodesInTree.map(node => node.id);
      this.props.selectHandler(sectorIds, !this.props.selections.get(sector.id));
    };
    const branchActions = [
      {
        icon: faCircleCheck,
        label: 'Select All',
        buttonClassModifier,
        clickHandler: selectHandler,
      },
      {
        icon: faCircleArrowRight,
        label: 'Expand',
        clickHandler: this.props.exploreHandler,
      }
    ];
    const leafActions = [
      {
        icon: faCircleCheck,
        label: 'Select',
        buttonClassModifier,
        clickHandler: selectHandler,
      }
    ];
    return sector.children.length ? branchActions : leafActions;
  }
}

export default SectorCardCollection;