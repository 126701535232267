import axios from "axios";
import SectorJsonParser from "../util/SectorJsonParser";

export async function loadSectors() {
  try {
    const response = await axios.get('/.netlify/functions/sectors');
    console.log(response.data);
    return SectorJsonParser(response.data);
  } catch (error) {
    console.error(error);
  }
}