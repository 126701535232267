import React from 'react';
import { connect } from 'react-redux';

import './CompanyGrid.css';

const columns = [
  {
    key: 'company',
    name: 'Company',
    formatter(props) {
      const { ticker, company } = props.row;
      return `${company} (${ticker})`;
    },
  },
  { key:
    'industries',
    name: 'Industries',
    formatter(props) {
      const { industries } = props.row;
      return industries ? (
        <ul>
          {Object.values(industries).map((industry, index) => (<li key={index}>{industry}</li>))}
        </ul>
      ) : [];
    },
  },
  { key: 'desc', name: 'Description' },
];

class SelectableCell extends React.Component {
  render() {
    return (
      <td key={`select-${this.props.index}`}>{<input type="checkbox" checked={this.props.selected} onChange={this.handleChange.bind(this)} />}</td>
    );
  }

  handleChange(e) {
    const { row, index, onSelect } = this.props;
    onSelect(index, row, e.target.checked);
  }
}

class TableCell extends React.Component {
  render() {
    const { index, row, column } = this.props;
    const value = column.formatter
      ? column.formatter({ row })
      : row[column.key];
    return (
      <td key={index} data-key={column.key}>{value}</td>
    );
  }
}

class TableRow extends React.Component {
  render() {
    const { index, row, columns, selected, onSelect } = this.props;
    let cells = [];
    if (onSelect) {
      cells.push(<SelectableCell index={index} row={row} selected={selected} onSelect={onSelect} />)
    }
    cells = cells.concat(columns.map((column, j) => (
      <TableCell index={j} column={column} row={row} />
    )));
    return (
      <tr key={index}>{ cells }</tr>
    );
  }
}

class SimpleTable extends React.Component {
  render() {
    const { rows, columns, selectedRows, onSelect } = this.props;
    const headerCells = columns.map(column => (
      <th data-key={column.key}>{column.name}</th>
    ));
    const bodyRows = rows.map((row, index) => (
      <TableRow index={index} columns={columns} row={row} selected={selectedRows.includes(index)} onSelect={onSelect} />
    ));
    return (
      <table>
        <thead>
          <tr>{ onSelect ? [(<th></th>)].concat(headerCells) : headerCells }</tr>
        </thead>
        <tbody>
          {bodyRows}
        </tbody>
      </table>
    );
  }
}

class CompanyGrid extends React.Component {
  render() {
    const selected = this.props.companies
      .reduce((arr, company, index) => {
        const { ticker, exchange } = company;
        const symbol = `${ticker}:${exchange}`;
        if (this.props.filters[symbol]) {
          return [...arr, index];
        }
        return arr;
      }, []);
    return (
      <section>
        <SimpleTable
          columns={columns}
          rows={this.props.companies}
          selectedRows={selected}
          onSelect={this.onSelectHandler.bind(this)}
          />
      </section>
    );
  }

  onSelectHandler(index, row, checked) {
    const { ticker, exchange } = row;
    const symbol = `${ticker}:${exchange}`;
    this.props.filterEntities(symbol, checked);
  }
}

const mapState = state => (state.MetricsSelector);
const mapDispatch = dispatch => (dispatch.MetricsSelector);

export default connect(mapState, mapDispatch)(CompanyGrid);