import React from 'react';
import { Provider, connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { init } from '@rematch/core';
import { loadCompanies } from '../services/CompanyService';
import ChartControls from './ChartControls';
import CompanyGrid from './CompanyGrid';

import { createMetricsSelectorModel, ENTITY_TYPE } from '../models/MetricsSelector';

const SUPPORT_METRICS = [
  {
    label: 'Market Capitalization',
    value: 'market_cap',
    dataType: 'big_money',
  },
  {
    label: 'Trailing P/E',
    value: 'trailing_pe',
    dataType: 'decimal',
  },
  {
    label: 'Forward P/E',
    value: 'forward_pe',
    dataType: 'decimal',
  },
  {
    label: 'Revenue Trailing 12 Mon',
    value: 'revenue_ttm',
    dataType: 'big_money',
  },
  {
    label: 'Gross Profit Trailing 12 Mon',
    value: 'gross_profit_ttm',
    dataType: 'big_money',
  },
  {
    label: 'Total Debt',
    value: 'total_debt_mrq',
    dataType: 'big_money',
  },
  {
    label: 'Current Ratio',
    value: 'current_ratio_mrq',
    dataType: 'decimal',
  },
];

const store = init({
  models: {
    MetricsSelector: createMetricsSelectorModel(ENTITY_TYPE.COMPANY, SUPPORT_METRICS),
  }
});

class CompanyComparison extends React.Component {
  constructor(props) {
    super(props);

    const { selections } = this.props;
    const selectedSectors = [];
    selections.forEach((selected, id) => {
      if (selected) {
        selectedSectors.push(id);
      }
    });

    this.state = {
      companies: [],
      selectedSectors,
    };
  }

  componentDidMount() {
    const { selectedSectors } = this.state;
    if (selectedSectors.length > 0) {
      loadCompanies(selectedSectors)
        .then(loaded => {
          this.setState({ companies: loaded });
          const entities = loaded.map(company => `${company.ticker}:${company.exchange}`);
          store.dispatch({ type: 'MetricsSelector/setEntities', payload: entities });
          const chartData = {
            labels: entities.slice(),
            series: [
              {
                title: 'Market Capitalization',
                values: loaded.map(company => company.market_cap),
                dataType: 'big_money'
              },
            ],
          };
          store.dispatch({ type: 'MetricsSelector/populateChart', payload: chartData });
        });
    }
  }

  render() {
    const { selectedSectors } = this.state;
    if (selectedSectors.length === 0) {
      return (<Navigate to='/' replace={true}/>);
    }

    const { companies } = this.state;
    return (
      <div>
        <Provider store={store}>
          <ChartControls supportedMetrics={SUPPORT_METRICS} />
          <CompanyGrid companies={companies} />
        </Provider>
      </div>
    );
  }
}

const mapState = state => (state.SectorExplorer);
const mapDispatch = dispatch => (dispatch.SectorExplorer);

export default connect(mapState, mapDispatch)(CompanyComparison);