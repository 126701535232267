import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import { init } from "@rematch/core";

import logo from './canopy-logo-white-212.png';
import './App.css';

import SectorExplorer from './components/SectorExplorer';
import { loadSectors } from './services/SectorExplorer';
import * as SectorExplorerModel from './models/SectorExplorer';
import CompanyComparison from './components/CompanyComparison';

const store = init({
  models: { ...SectorExplorerModel }
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectors: [],
    };
  }

  componentDidMount() {
    loadSectors()
      .then(loaded => {
        this.setState({ sectors: loaded });
      })
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
        </header>
        <div className='content'>
          <Routes>
            <Route path="/" element={this.renderSectorExplorer()} />
            <Route path="companies" element={this.renderCompanyList()} />
          </Routes>
        </div>
      </div>
    );
  }

  renderSectorExplorer() {
    return (this.state.sectors)
      ? (
        <Provider store={store}>
          <nav>
            <Link to="/companies">See Companies</Link>
          </nav>
          <h2 style={{ marginBottom: 'auto' }}>Choose the sectors your want to explore</h2>
          <SectorExplorer sectors={this.state.sectors} />
        </Provider>
      )
      : null;
  }

  renderCompanyList() {
    return (
      <Provider store={store}>
        <nav>
          <Link to="/">Choose Sectors</Link>
        </nav>
        <h3>Companies</h3>
        <CompanyComparison />
      </Provider>
    );
  }
}

export default App;
