import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SectorCard.css';

class SectorCard extends React.Component {
  render() {
    let /*cardImageStyle,*/ cardActions;
    const cardContainerClassNames = `card-container ${this.props.disabled ? 'disabled' : 'active'}`;

    // if (this.props.cardImage) {
    //   cardImageStyle = { backgroundImage: `url('${this.props.cardImage}')` }
    // }

    if (this.props.actions) {
      cardActions = this.props.actions.map((action, index) => {
        let buttonClassNames = 'card-action-button';
        if (typeof action.buttonClassModifier === 'function') {
          buttonClassNames += ' ' + action.buttonClassModifier(index, this.props);
        }
        const clickHandler = () => {
          if (!this.props.disabled) {
            if (typeof action.clickHandler === 'function') {
              action.clickHandler(this.props.id);
            }
          }
        };
        return (
          <div className={buttonClassNames} key={index} onClick={clickHandler}>
            <FontAwesomeIcon icon={action.icon} />
            <div className='card-action-label'>{action.label}</div>
          </div>
        );
      });
    }

    return (
      <div className={cardContainerClassNames}>
        {/* <div className='card-image' style={cardImageStyle}></div> */}
        <div className='card-body'>
          <div className='card-content'>
            <h2>{ this.props.title || 'Title' }</h2>
            <p>{ this.props.desc || 'Description'}</p>
          </div>
          <div className='card-actions'>
            { cardActions }
          </div>
        </div>
      </div>
    );
  }
}

export default SectorCard;