export const SectorExplorer = {
  state: {
    selections: new Map(),
    breadcrumbs: [],
  },
  reducers: {
    selectSectors(state, sectorIds, selected) {
      const { selections, breadcrumbs } = state;
      const newSelections = new Map(selections);
      sectorIds.forEach(id => newSelections.set(id, selected));
      return {
        breadcrumbs,
        selections: newSelections,
      };
    },
    exploreSector(state, sectorId) {
      const { selections, breadcrumbs } = state;
      const newBreadcrumbs = breadcrumbs.slice(0).concat([sectorId]);
      return {
        breadcrumbs: newBreadcrumbs,
        selections,
      };
    },
    navigateBack(state) {
      const { selections, breadcrumbs } = state;
      const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length-1);
      return {
        breadcrumbs: newBreadcrumbs,
        selections,
      };
    },
  },
};