import React from 'react';
import { connect } from 'react-redux';

import './MetricsSelector.css';

class MetricsSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMetrics: {
        1: 'market_cap',
      },
    };
  }

  render() {
    const { supportedMetrics } = this.props;
    return (
      <div className='metrics-selector-container'>
        <h4>Choose Metrics To Graph</h4>
        <div className='label'>Metric 1</div>
        <select name="1" onChange={this.changeHandler.bind(this)}>
          {
            supportedMetrics.map((metric, index) => {
              const isDisabled = (this.state.selectedMetrics[2] === metric.value);
              return (<option key={index} value={metric.value} disabled={isDisabled}>{metric.label}</option>);
            })
          }
        </select>
        <div className='label'>Metric 2</div>
        <select name="2" onChange={this.changeHandler.bind(this)}>
          {
            [(<option key="-1" value=""></option>)].concat(
              supportedMetrics.map((metric, index) => {
                const isDisabled = (this.state.selectedMetrics[1] === metric.value);
                return (<option key={index} value={metric.value} disabled={isDisabled}>{metric.label}</option>);
              })
            )
          }
        </select>
      </div>
    );
  }

  changeHandler(event) {
    const axis = event.target.name;
    const metric = event.target.selectedOptions[0].value;
    this.setState((state, props) => {
      const selectedMetrics = {
        ...state.selectedMetrics
      };

      if (!metric) {
        delete selectedMetrics[axis];
      } else {
        selectedMetrics[axis] = metric;
      }

      this.loadMetrics(selectedMetrics);
      return { selectedMetrics };
    });
  }

  loadMetrics(selectedMetrics) {
    const metrics = [1, 2].map(index => selectedMetrics[index]).filter(_ => _);
    this.props.loadMetrics(metrics);
  }
}

const mapState = state => (state.MetricsSelector);
const mapDispatch = dispatch => (dispatch.MetricsSelector);

export default connect(mapState, mapDispatch)(MetricsSelector);