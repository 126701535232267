import React from 'react';
import { connect } from "react-redux";
import SectorCardCollection from './SectorCardCollection';
import './SectorExplorer.css';

class SectorExplorer extends React.Component {
  render() {
    const { sectors, selections, breadcrumbs } = this.props;
    const selectHandler = this.props.selectSectors;
    const exploreHandler = this.props.exploreSector;
    const backHandler = this.props.navigateBack;
    const collections = [(
      <SectorCardCollection
        key='root'
        sectors={sectors}
        selections={selections}
        disabled={breadcrumbs.length}
        selectHandler={selectHandler}
        exploreHandler={exploreHandler}
        backHandler={backHandler} />
    )];
    appendSectorCardCollection(
      collections,
      sectors,
      breadcrumbs,
      selections,
      selectHandler,
      exploreHandler,
      backHandler);
    return (
      <div className='sector-explorer-container'>
        {collections}
      </div>
    );
  }
}

function appendSectorCardCollection(collections, sectors, progress, selections, selectHandler, exploreHandler, backHandler) {
  if (progress.length) {
    // Go to next level
    const currentParent = sectors.find(sector => sector.id === progress[0]);
    const nextProgress = progress.slice(1);
    collections.push((<SectorCardCollection
      key={currentParent.id}
      parent={currentParent}
      sectors={currentParent.children}
      selections={selections}
      disabled={nextProgress.length}
      selectHandler={selectHandler}
      exploreHandler={exploreHandler}
      backHandler={backHandler} />));
    appendSectorCardCollection(collections, currentParent.children, nextProgress, selections, selectHandler, exploreHandler, backHandler);
  }
}

const mapState = state => (state.SectorExplorer);
const mapDispatch = dispatch => (dispatch.SectorExplorer);

export default connect(mapState, mapDispatch)(SectorExplorer);