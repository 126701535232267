import { loadStatistics } from '../services/CompanyService';

export const ENTITY_TYPE = {
  COMPANY: Symbol('company'),
  INDUSTRY: Symbol('industry'),
};

const MetricsSelector = {
  state: {
    chartData: {},
    entities: [],
    filters: {},
  },
  reducers: {
    filterEntities(state, entityId, included) {
      const filters = {
        ...state.filters,
        [entityId]: included,
      };
      return {
        ...state,
        filters,
      };
    },
    setEntities(state, entities) {
      return {
        ...state,
        entities,
        filters: entities.reduce((map, entity) => {
          return {
            ...map,
            [entity]: true,
          };
        }, {}),
      };
    },
    populateChart(state, chartData) {
      return {
        ...state,
        chartData,
      };
    },
  },
};

export function createMetricsSelectorModel(entityType, supportedMetrics) {
  if (entityType === ENTITY_TYPE.COMPANY) {
    return {
      ...MetricsSelector,
      effects: {
        async loadMetrics(metrics, rootState) {
          const data = await loadStatistics(rootState.MetricsSelector.entities, metrics);
          const labels = data.map(company => company.ticker);
          const series = metrics.map(metricType => {
            const metric = supportedMetrics.find(obj => obj.value === metricType)
            const { label: title, dataType } = metric;
            const values = data.map(company => company.values[metricType].value);
            return { title, values, dataType };
          });
          this.populateChart({ labels, series });
        },
      },
    };
  }
  return MetricsSelector;
}