class Sector {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.desc = props.desc;
    this.image = props.image;
    this.children = [];
  }

  addChildNode(childNode) {
    this.children.push(childNode);
    this.children.sort(sortCompare);
  }

  getAllNodes() {
    return this.children.reduce((nodes, child) => {
      return nodes.concat(child.getAllNodes());
    }, [this]);
  }
}

const nodeCache = new Map();

export default function SectorJsonParser(json) {
  const rootNodes = [];
  json.forEach(nodeJson => {
    nodeCache.set(nodeJson.id, new Sector(nodeJson));
  });
  json.forEach(nodeJson => {
    const childNode = nodeCache.get(nodeJson.id);
    if (!nodeJson.parents) {
      rootNodes.push(childNode);
      return;
    }
    nodeJson.parents.forEach(parentId => {
      const parentNode = nodeCache.get(parentId);
      parentNode.addChildNode(childNode);
    });
  });
  rootNodes.sort(sortCompare);
  return rootNodes;
};

function sortCompare(nodeA, nodeB) {
  if (nodeA.children.length && !nodeB.children.length) {
    return -1; // A has children and B does not
  }
  if (!nodeA.children.length && nodeB.children.length) {
    return 1; // B has children and A does not
  }
  if (nodeA.name < nodeB.name) {
    return -1;
  }
  if (nodeA.name > nodeB.name) {
    return 1;
  }
  return 0;
}